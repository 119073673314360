.phaeno-minigame {
	margin-bottom: $baseSize;
	&__content {
		opacity: 0;

		width: 100%;
		height: 400px;
		background-color: $colorBg;
	}
	&__head {
		padding-top: $baseSize4;
	}
	&__hype {
		margin: auto;
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
