.phaeno-logo {
	pointer-events: none;
	* {
		pointer-events: none;
	}

	&__logo, &__slogan {
		position: absolute;
		top: 0;
		left: 0;
		width: $logoWidth;
		height: $logoHeight;
		display: block;

		opacity: 1;
		@include ease(opacity, $animDuration4);

		svg {
			width: $logoWidth;
			height: $logoHeight;
		}
	}

	&__slogan {
		display: none;
		top: 7px;
		@include breakpoint($breakSmall) {
			display: block;
		}
	}

	body.scrolled & {
		.phaeno-logo__slogan {
			opacity: 0;
		}
	}

}
