/* classes for EXT:FORM */
/* does not follow BEM conventions */

$stepsHeight: 50px;

.phaeno-formFieldset {
	color: $colorByNameGrey;

	.form-group,
	p {
		margin-bottom:1em;
	}
	&.form-group {
		margin-bottom:2em;
	}
	@extend .phaeno-form__fieldset;

	legend {
		@extend .phaeno-form__legend;
		font-size: 2rem;
	}

	label {
		@extend .phaeno-form__label;
		text-transform: none;
		color:$colorByNameGrey;
	}
	textarea, input[type=text] {
		@extend .phaeno-form__text;
	}
	.form-check input {
		margin-right: 1em;
	}
}
button.btn-primary {
	@extend .phaeno-form__button;
}
