/**
 * @license
 * MyFonts Webfont Build ID 3337001, 2017-01-26T12:49:06-0500
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/32eb24");

@font-face {
	font-family: 'BrandonTextWeb-Black';
	src: url('../Fonts/32EB24_0_0.eot');
	src: url('../Fonts/32EB24_0_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_0_0.woff2') format('woff2'), url('../Fonts/32EB24_0_0.woff') format('woff'), url('../Fonts/32EB24_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-BoldItalic';
	src: url('../Fonts/32EB24_1_0.eot');
	src: url('../Fonts/32EB24_1_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_1_0.woff2') format('woff2'), url('../Fonts/32EB24_1_0.woff') format('woff'), url('../Fonts/32EB24_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Bold';
	src: url('../Fonts/32EB24_2_0.eot');
	src: url('../Fonts/32EB24_2_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_2_0.woff2') format('woff2'), url('../Fonts/32EB24_2_0.woff') format('woff'), url('../Fonts/32EB24_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-BlackItalic';
	src: url('../Fonts/32EB24_3_0.eot');
	src: url('../Fonts/32EB24_3_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_3_0.woff2') format('woff2'), url('../Fonts/32EB24_3_0.woff') format('woff'), url('../Fonts/32EB24_3_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-LightItalic';
	src: url('../Fonts/32EB24_4_0.eot');
	src: url('../Fonts/32EB24_4_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_4_0.woff2') format('woff2'), url('../Fonts/32EB24_4_0.woff') format('woff'), url('../Fonts/32EB24_4_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Light';
	src: url('../Fonts/32EB24_5_0.eot');
	src: url('../Fonts/32EB24_5_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_5_0.woff2') format('woff2'), url('../Fonts/32EB24_5_0.woff') format('woff'), url('../Fonts/32EB24_5_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Italic';
	src: url('../Fonts/32EB24_6_0.eot');
	src: url('../Fonts/32EB24_6_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_6_0.woff2') format('woff2'), url('../Fonts/32EB24_6_0.woff') format('woff'), url('../Fonts/32EB24_6_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-MediumItalic';
	src: url('../Fonts/32EB24_7_0.eot');
	src: url('../Fonts/32EB24_7_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_7_0.woff2') format('woff2'), url('../Fonts/32EB24_7_0.woff') format('woff'), url('../Fonts/32EB24_7_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Regular';
	src: url('../Fonts/32EB24_8_0.eot');
	src: url('../Fonts/32EB24_8_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_8_0.woff2') format('woff2'), url('../Fonts/32EB24_8_0.woff') format('woff'), url('../Fonts/32EB24_8_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Medium';
	src: url('../Fonts/32EB24_9_0.eot');
	src: url('../Fonts/32EB24_9_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_9_0.woff2') format('woff2'), url('../Fonts/32EB24_9_0.woff') format('woff'), url('../Fonts/32EB24_9_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-ThinItalic';
	src: url('../Fonts/32EB24_A_0.eot');
	src: url('../Fonts/32EB24_A_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_A_0.woff2') format('woff2'), url('../Fonts/32EB24_A_0.woff') format('woff'), url('../Fonts/32EB24_A_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BrandonTextWeb-Thin';
	src: url('../Fonts/32EB24_B_0.eot');
	src: url('../Fonts/32EB24_B_0.eot?#iefix') format('embedded-opentype'), url('../Fonts/32EB24_B_0.woff2') format('woff2'), url('../Fonts/32EB24_B_0.woff') format('woff'), url('../Fonts/32EB24_B_0.ttf') format('truetype');
}

@mixin sansSerif() {
	font-family: "BrandonTextWeb-Regular", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin sansSerifBold() {
	font-family: "BrandonTextWeb-Regular", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
}

@mixin sansSerifBlack() {
	font-family: "BrandonTextWeb-Black", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: normal;
}

@mixin sansSerifMedium() {
	font-family: "BrandonTextWeb-Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin sansSerifLight() {
	font-family: "BrandonTextWeb-Light", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
