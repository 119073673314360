.phaeno-imageFooter {
	position: relative;
	color: $colorTextSecond;
	width: 100%;
	height: auto;
	padding: $baseSize2;

	@include breakpoint($breakSmall) {
		background-color: $colorBgAlpha;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	&__link {
		border-bottom: none;
	}

	&__text {
		display: block;
		width: 100%;
		padding-right: $baseSize2;
	}

	&__icon {
		position: absolute;
		top: $baseSize8;
		right: $baseSize4;
		transform: scale(-1, 1);
		width: $arrowIconSize;
		height: $arrowIconSize;
		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}
}
