.phaeno-footer {
	margin-top: $baseSize * 2;
	padding-top: $baseSize;

	color: $colorTextHighlighted;

	// flicker avoidance
	transform: translate3d(0,0,0);

	&__content {
		background-color: $colorBgSecond;
	}

	//
	// MAIN PART
	//
	&__main {
		position: relative;
		vertical-align: top;
		width: 100%;
		@include clearfix;
	}

	&__addresses {
		position: relative;

		width: span(12);
		@include breakpoint($breakSmall ) {
			width: span(6);
		}
		@include breakpoint($breakMedium) {
			width: span(8);
		}
	}

	&__address {
		padding: $baseSize2;
		position: relative;
		float: left;
		width: span(12);
		@include breakpoint($breakMedium) {
			width: span(6);
		}
	}

	&__addressText {
		color: $colorTextHighlighted;
	}

	&__social {
		padding: $baseSize2;
		display: inline-block;
		vertical-align: top;
		width: span(12);
		@include breakpoint($breakSmall ) {
			width: span(6);
		}
		@include breakpoint($breakMedium) {
			width: span(4);
		}
	}
	&__socialButtons {
		margin-top: $baseSize4;
		margin-bottom: $baseSize4;
	}

	&__socialButton {
		display: inline-block;
		margin-right: $baseSize4;
	}
	&__socialButton:hover {
		opacity: 0.7;
		@include ease(opacity);
	}

	&__socialIcon {
		width: $footerSocialIconSize;
		height: $footerSocialIconSize;
		svg {
			width: $footerSocialIconSize;
			height: $footerSocialIconSize;
		}
	}

	//
	// LOGOS
	//

	&__logos {
		width: 100%;
	}
	&__logosHead {
		padding: $baseSize2 $baseSize2 $baseSize8 $baseSize2;
	}
	&__logosContainer {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		background-color: $colorBg;
		padding-top: $baseSize4;
		padding-bottom: $baseSize4;
	}
	&__partnerLogo {
		display: inline-block;
		width: auto;
		height: $footerLogoHeight;
		margin: $baseSize2 $baseSize;
	}


	//
	// FOOTER
	//

	&__bottom {
		padding: $baseSize4 $baseSize2;
		@include clearfix;
	}

	&__bottomCopy {
		float: left;
	}

	&__bottomMenu {
		float: left;
		@include breakpoint($breakSmall) {
			float: right;
		}
	}

	&__bottomMenuLink {
		display: inline;
		background: 0;
		color: inherit;

		&:not(:last-child):after {
			content: " | ";
		}
	}



	//
	// LOGO
	//
	&__logo, &__slogan {
		position: absolute;
		right: $baseSize8;
		top: -$baseSize4;
		width: $logoWidth;
		height: $logoHeight;
		svg {
			width: $logoWidth;
			height: $logoHeight;
		}
		@include breakpoint($breakSmall) {
			right: $baseSize8 * 3;
		}
	}

	&__slogan {
		display: none;
		@include breakpoint($breakSmall) {
			display: block;
		}
	}
}
