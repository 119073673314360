.phaeno-imagesInfoBox {
	margin-top: $baseSize;
	margin-bottom: $baseSize;

	&__container {
		// opacity: 0;
	}

	&__content {
		@include clearfix;
	}

	&__image {
		width: 100%;
		height: auto;
		display: block;
		pointer-events: none;
	}
}
