.phaeno-menuNextPrev {
	position: relative;
	width: 100%;
	margin-top: $baseSize4;

	&__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		width: $arrowNextPrevIconSize * 2;
		height: $arrowNextPrevIconSize * 6;
		svg {
			width: $arrowNextPrevIconSize;
			height: $arrowNextPrevIconSize;
			background-color: rgba(255, 255, 255, 0.70);
			border-radius: 5px;
		}
	}

	&__arrow--next {
		top: 50%;
		transform: translateY(-50%) scale(-1, 1);
		right: 0;
	}

	&__arrow--prev {
		left: 0;
	}

	&__title {
		width: 100%;
		max-width: 100%;
		text-align: center;
		font-size: $fontSizeCopy;
		@include sansSerifBold;
		text-transform: uppercase;
	}

	&__nav {
		text-align: center;
	}

	&--bg {
		background-color: $colorBg;
		padding-top: $baseSize4;
		padding-bottom: $baseSize4;
	}
}
