.phaeno-sameHeightColumns {

	&__column {
		padding: $baseSize2;
		position: relative;

		&--nopad {
			padding: 0 $baseSize2 0 0;
		}
	}
	@include breakpoint($breakMedium) {
		display: flex;
		&__column {
			height: 100%;
		}
	}

	&--bg {
		.phaeno-sameHeightColumns__column {
			background: $colorBg;
		}
	}
}
