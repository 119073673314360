.phaeno-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width:100%;
	z-index: 10;
	background-color: $colorBg;
	border-bottom: 3px solid rgba($colorByNameRed, 0);
	border-radius: 1px;
	transition: border-color 0.3s;

	body.scrolled & {
		border-color: rgba($colorByNameRed, 1);
		transition: border-color 1s;
	}
}
