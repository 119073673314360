.phaeno-ticketsTable {
	margin-bottom: $baseSize2;

	&__row {

	}

	&__text {
		@include fontSizeCopy;
		color: $colorTextSecond;
		font-weight: normal;
		padding-top: $baseSize4;
		border-bottom: 1px dotted $colorByNameGreyLight;

		&--right {
			text-align: right;
		}
	}
}
