.phaeno-image {
	width: 100%;
	height: auto;
	position: relative;

	&__embed-item {
		width: auto;
		max-width: 100%;
		height: auto;
		display: block; /* img margin workaround */
	}

	&__zoom {
		position: absolute;
		bottom: $baseSize;
		right: $baseSize;

		width: $infoBoxIconSize;
		height: $infoBoxIconSize;

		svg {
			width: $infoBoxIconSize;
			height: $infoBoxIconSize;
		}
	}

	&__caption {
		background-color: $colorBgAlpha;
		color: $colorTextSecond;
		position: absolute;
		width: 100%;
		bottom: 0;
		padding: $baseSize4 $baseSize4;

		&--small {
			padding: $baseSize8 $baseSize4;
			@include fontSizeCopySmall;
		}
	}

	&--intext {
		max-width: 25%;
		display: inline-block;
	}
	&--left {
		float: left;
		margin-right: $baseSize2;
	}
	&--right {
		float: right;
		margin-right: $baseSize;
	}
	&--above,
	&--below {
		float: none;
		margin-right: 0;
		margin-left: 0;
	}
}
