@import "../global/variables";

.phaeno-dashMenu {
	width: $baseSize;

	&__button {
		position: relative;
		margin-bottom: 1.5 * $baseSize8;
		background-color: $colorInactive;
		margin-left: $baseSize4;
		width: $baseSize2;
		height: $bulletWidth;
		border-radius: $bulletWidth;

		@include breakpoint($breakSmall) {
			margin-bottom: $baseSize8;
		}
	}

	&__button.active {
		background-color: $colorText;
	}

	&__buttonBtn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: $bulletWidth * 6;
		cursor: pointer;
	}

	&__button--short {
		width: $bulletWidth;
		height: $bulletWidth;
	}
}

.phaeno-dashMenu--horizontal {
	width: auto;

	.phaeno-dashMenu__button {
		display: inline-block;
		margin-left: 0;
		margin-right: $baseSize8;
		width: $baseSize8;
	}
}
