.phaeno-tueftelmaeuse {

	&__logo {
		position:absolute;
		top:9%;
		left:5%;
		width:44%;
		height: auto;

		@include breakpoint($breakSmall) {
			top:4%;
			left:5%;
			width:40%;
			height: auto;
		}
	}

}
