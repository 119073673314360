
@mixin fontSizeCopy() {
    font-size: $fontSizeCopyReduced;
    line-height: $lineHeightCopy;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeCopy;
    }
}

@mixin fontSizeCopySmall() {
    font-size: $fontSizeCopySmallReduced;
    line-height: $lineHeightCopySmall;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeCopySmall;
    }
}

@mixin fontSizeSubtext() {
    font-size: $fontSizeSubtextReduced;
    line-height: $lineHeightSubtext;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeSubtext;
    }
}

@mixin fontSizeHead() {
    font-size: $fontSizeHeadReduced;
    line-height: $lineHeightHead;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeHead;
    }
}

@mixin fontSizeHeadMedium() {
    font-size: $fontSizeHeadMediumReduced;
    line-height: $lineHeightHeadMedium;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeHeadMedium;
    }
}

@mixin fontSizeHeadSmall() {
    font-size: $fontSizeHeadSmallReduced;
    line-height: $lineHeightHeadSmall;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeHeadSmall;
    }
}

@mixin fontSizeHuge() {
    font-size: $fontSizeHugeReduced;
    line-height: $lineHeightHuge;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeHuge;
    }
}

@mixin fontSizeHuge2() {
    font-size: $fontSizeHugeReduced2;
    line-height: $lineHeightHuge2;
    @include breakpoint($breakMedium) {
        font-size:  $fontSizeHuge2;
    }
}
