.phaeno-video {
	position: relative;
	margin-bottom: $baseSize2;

	background-color: $colorBgChoosen;

	width: 100%;
	height: auto;

	&__video {
		display: block;
		width: 100%;
		height: auto;
	}

	&__play {
		@include center(both);

		width: $videoPlaySize /2;
		height: $videoPlaySize /2;
		svg {
			width: $videoPlaySize /2;
			height: $videoPlaySize /2;
		}
		@include breakpoint($breakMenu) {
			width: $videoPlaySize;
			height: $videoPlaySize;
			svg {
				width: $videoPlaySize;
				height: $videoPlaySize;
			}
		}
	}

	&__embed {
		display: block;
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 0;
		height: 0;
		overflow: hidden;
		cursor: pointer;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border:none;
		}
	}
	&__embed-item {
		width: 100%;
	}
}
