.phaeno-more {

	text-align: right;
	width:100%;

	&--left {
		text-align: left;
	}
	&__link {
		display: inline-block;
		@include fontSizeHeadSmall;
		color: $colorText;
	}
	&__icon {
		display: inline-block;
		margin-bottom: 4px;
		vertical-align: middle;
		transform: scale(-1, 1);
		width: $arrowIconSize;
		height: $arrowIconSize;
		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}
}