.phaeno-calendar {
	$b: &;

	padding-top: $baseSize;
	padding-bottom: $baseSize;
	-webkit-transform: translateZ(0px);

	&__monthButton {
		color: $colorText;
		@include fontSizeHeadSmall;

		&--current {
			font-weight: bold;
		}

		&.active {
			color: $colorTextSecond;
		}
	}

	&__month {
		position: relative;
		margin-bottom: $baseSize;
	}

	&__day {
		position: relative;
		border: {
			width: 1px;
			color: $colorByNameGreyLight;
			top-style: solid;
		}

		&:last-child {
			border-bottom-style: solid;
		}
	}

	&__dayLink {
		display: block;
	}

	&__dayContent {
		cursor: pointer;
		@include ease(padding background-color);
		overflow: hidden;
		display: flex;
	}

	&__events {
		display: block;
		width: 100%;
		min-height: 2rem;
		padding: {
			top: $baseSize4;
			bottom: $baseSize4;
			left: 0;
			right: 0;
		}
	}

	&__event {
		position: relative;
		margin-top: 0;
		height: 0;
		overflow: hidden;
		@include ease(height);

		&:not(&--open) {
			.phaeno-calendar__eventElement:hover {
				background-color: $colorBgHighlightedSecond;
			}
		}

		&--open {
			display: block;
			overflow: visible;
			height: $calendarEventHeight;

			.phaeno-calendar__eventElement {
				background-color: #e0e0e0;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 1;

				&:hover {
					background-color: #f0f0f0;
				}
			}

			.phaeno-calendar__eventHead {
				color: $colorByNameGrey;
			}
		}
	}

	&__leftColumn {
		flex-shrink: 0;
		flex-grow: 0;
		width: $calendarLeftColumnWidth;
		background-color: $colorBgHover;
		position: relative;

		color: $colorTextSecond;
		@include ease();
		z-index: 5;

		.phaeno-calendar__day:hover & {
			color: $colorText;
		}
	}

	&__rightColumn {
		flex-shrink: 1;
		flex-grow: 1;
		border-left: {
			style: solid;
			width: $baseSize16;
			color: $colorBg;
		}
		padding: {
			left: $baseSize4;
			right: $baseSize4;
		}
	}

	&__eventIcon {
		position: absolute;
		right: $baseSize8;
		top: 0;
		width: $arrowIconSize;
		height: $calendarEventHeight;
		transform: scale(-1, -1);
		overflow: hidden;

		svg {
			width: $arrowIconSize;
			height: $calendarEventHeight;
		}
	}

	&__button {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
	}

	&__eventElement {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;

		height: $calendarEventHeight;
		background-color: $colorBgHighlighted;
		border-radius: $calendarEventHeight;

		opacity: 0;
		@include ease((opacity, background-color));
	}

	&__eventHead {
		color: $colorTextHighlighted;
		@include fontSizeSubtext;
		@include sansSerifMedium;
		padding: {
			left: $baseSize4;
			right: $baseSize8;
		}
		letter-spacing: 0.03rem;
		text-align: left;
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
	}

	&__eventHeadText {
		display: inline-block;
		flex-shrink: 1;
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: {
			top: $baseSize8;
			bottom: $baseSize8;
		}
	}

	&__eventHeadIcon {
		display: inline-block;
		transform: scaleX(-1);
		flex-shrink: 0;
		flex-grow: 0;
		line-height: $calendarEventHeight;

		svg {
			width: $arrowIconSize * 0.7;
			height: $arrowIconSize * 0.7;
			vertical-align: middle;
		}
	}

	&__dayInfoDate {
		display: block;
		font-size: $fontSizeHeadMedium;
		line-height: $fontSizeHeadMedium;
		margin-left: $baseSize8;
		margin-top: $baseSize16;
	}

	&__dayInfoDay {
		position: absolute;
		@include fontSizeSubtext;
		top: $baseSize16;
		right: $baseSize16;
		opacity: 1;
		@include ease;

		.phaeno-calendar__day:hover & {
			color: $colorText;
		}
	}

	&__additionalLabelHead {
		@include fontSizeSubtext;
		position: absolute;
		top: $baseSize4;
		left: $baseSize4;
	}

	&__dayInfoDayFull {
		@include fontSizeSubtext;
		font-weight: bold;
		color: $colorByNameBlack;
		margin-left: $baseSize8;
		opacity: 0;
		@include ease(opacity);
	}

	&__timeMarkContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding-left: 90px;
		padding-right: 11px;
	}

	&__timeMark {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__timeMarkLine {
		background-color: $colorText;
		border-radius: 10px;
		opacity: 0;
		position: absolute;
		width: 15px;
		height: 15px;
		top: 4px;
		left: 0;
		z-index: 3;

		@include breakpoint(0 $breakSmall - 1) {
			display: none;
		}
	}

	//
	// AXE
	//
	&__axeContainer {
		position: relative;
		width: 100%;
		height: 0;

		@include breakpoint(0 $breakSmall - 1) {
			display: none;
		}
	}

	&__axe {
		@include ease(transform);
		transform: translateY(-100%);
	}

	&__axeTick {
		display: inline-block;
		width: 100% / $ticksNo;
		border: {
			width: 1px;
			color: $colorByNameGreyLight;
		}
		border-left-style: solid;

		&:last-child {
			border-right-style: solid;
		}

		&:first-of-type {
			margin-left: 0;
		}
	}

	&__axeTicktTxt {
		@include fontSizeSubtext;
		margin-left: $baseSize8;
		margin-top: $baseSize8;
	}

	&--simple &__month {
		margin-bottom: 0;
	}

	&__day--holiday {
		.phaeno-calendar__leftColumn {
			color: $colorTextAdditional;
		}
	}

	&__day--active {
		#{$b}__dayContent {
			background-color: $colorBgHover;
		}
	}

	&:not(&--simple) &__day--active,
	&--filtered:not(&--simple) {
		.phaeno-calendar__axeContainer {
			height: auto;
		}

		.phaeno-calendar__axe {
			transform: none;
		}

		.phaeno-calendar__axeTick {
			@include breakpoint($breakMedium) {
				opacity: 1;
			}
		}

		.phaeno-calendar__dayInfoDate {
			color: $colorByNameBlack;
		}

		.phaeno-calendar__dayInfoDay {
			opacity: 0;
		}

		.phaeno-calendar__dayInfoDayFull {
			opacity: 1;
		}

		.phaeno-calendar__event {
			height: $calendarEventHeight;
			margin-top: $baseSize8;
			overflow: visible;
		}

		.phaeno-calendar__eventElement {
			opacity: 1;
		}

		.phaeno-calendar__timeMarkLine {
			opacity: .5;
		}

	}

	&--filtered:not(&--simple) {
		.phaeno-calendar__axeContainer {
			border-color: #cbcbcb;
		}

		.phaeno-calendar__day--active {
			.phaeno-calendar__axeContainer {
				border-color: $colorByNameGreyLight;
			}
		}
	}
}
