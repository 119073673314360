.phaeno-slidingHighlights {
	padding-top: $baseSize * 2;

	.phaeno-menuNextPrev {
		padding-top: $baseSize2;
		padding-bottom: $baseSize2;
	}

	&__slides {
		width: 100%;
		overflow: hidden;
		position: relative;
		@include clearfix;
		min-height: 300px;
	}

	&__slidesContainer {
		position: absolute;
		left: 0;
		top: 0;
		width: 200%;
	}

	&__slide {
		vertical-align: top;
		width: 25%;
		display: inline-block;
		min-height: 300px;
		padding: $baseSize2;
		padding-top: 0;
		.phaeno-image {
			pointer-events: none;
			margin-bottom: $baseSize2;
		}
	}
}
