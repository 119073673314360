.phaeno-infoTab {
	margin-left: $baseSize2;
	margin-right: $baseSize2;
	padding-top: $baseSize4;

	&__head {
		@include fontSizeHeadSmall;
		color: $colorTextSecond;
		padding-top: $baseSize4;
	}

	&__head-link {
		color: $colorByNameRed;
	}

	&--minheight {
		@include breakpoint($breakMedium) {
			min-height: 400px;
		}
	}

	&__bodytext {
		table {
			margin-bottom: $baseSize2;
		}

		td {
			border-bottom: 1px dotted $colorByNameGreyLighter;
			vertical-align: top;
			padding: $baseSize8 $baseSize4 $baseSize8 0;

			p {
				margin: 0;
			}
		}

		p {
			margin-bottom: $baseSize4;
			clear: both;
		}

		a {
			@include sansSerif;
			cursor: pointer;
			color: $colorText;
			border: 0;
			text-decoration: none;

			&:hover,
			&:focus {
				border: 0;
				border-bottom: 1px solid $colorText;
			}
		}
	}

	&__image {
		position: relative
	}

	&__anfahrtmap {
		width: 100%;
		height: auto;
	}

	&__plan {
		width: 100%;
		height: auto;
	}

	&__close {
		position: absolute;
		right: 1.8rem;
		top: 1.8rem;
		z-index: 2;
	}
}
