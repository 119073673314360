//
// Showing a module with a grey mask
//

@mixin animation-show-visibility() {
	animation-name: showvisibility;
	animation-duration: $animDuration;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

@mixin animation-show-mask() {
	animation-name: showmask;
	animation-duration: $animDuration;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;

}

@keyframes showvisibility {
    0%   {visibility: hidden;}
    50%  {visibility: hidden;}
    51%  {visibility: visible;}
    100% {visibility: visible;}
}

@keyframes showmask {
    0%   {height: 0%; top: 0%;}
    49%  {height: 100%; top: 0%;}
    51%  {height: 100%; top: 0%;}
    100% {height: 0%; top: 100%;}
}


//
// animating button background
//

@mixin animation-mask-on() {
	animation-name: maskon;
	animation-duration: $animDuration4;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

@mixin animation-mask-off() {
	animation-name: maskoff;
	animation-duration: $animDuration4;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

@keyframes maskon {
    0%   {height: 0%; bottom: 0%;}
    100% {height: 100%; bottom: 0%;}
}
@keyframes maskoff {
    0%   {height: 100%; top: 0%;}
    100% {height: 0%; top: 0%;}
}



//
// animating scroll down arrow
//

@mixin animation-bounce-down() {
	animation-name: bouncedown;
	animation-duration: $animDurationLong * 1.7;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	animation-iteration-count: 4;
}

@keyframes bouncedown {
    0%   {padding-top: 0px;}
	10%  {padding-top: 9px;}
	20%  {padding-top: 0px;}
}


//
// animation show alpha
//
@mixin animation-show-alpha() {
	animation-name: showAlpha;
	animation-duration: $animDuration2;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
}
@keyframes showAlpha {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
