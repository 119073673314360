$fontBase: 62.5%;

$baseLineHeight: 1.2;
$copyLineHeight: 1.4;
$reducedFactor: 0.8;

$fontSizeHead: 3rem;
$fontSizeHeadReduced: 2.6rem;
$lineHeightHead: $baseLineHeight;

$fontSizeHuge: 10rem;
$fontSizeHugeReduced: 6.5rem;
$lineHeightHuge: 1;

$fontSizeHuge2: 6.4rem;
$fontSizeHugeReduced2: 4rem;
$lineHeightHuge2: $baseLineHeight;

$fontSizeHeadSmall: 2.0rem;
$fontSizeHeadSmallReduced: $fontSizeHeadSmall * $reducedFactor;
$lineHeightHeadSmall: $baseLineHeight;

$fontSizeHeadMedium: 2.4rem;
$fontSizeHeadMediumReduced: $fontSizeHeadMedium * $reducedFactor;
$lineHeightHeadMedium: $baseLineHeight;

$fontSizeCopy: 1.6rem;
$fontSizeCopyReduced: 1.4rem;
$lineHeightCopy: $copyLineHeight;

$fontSizeCopySmall: 1.4rem;
$fontSizeCopySmallReduced: 1.2rem;
$lineHeightCopySmall: $copyLineHeight;

$fontSizeSubtext: 1.2rem;
$fontSizeSubtextReduced: $fontSizeSubtext * $reducedFactor;
$lineHeightSubtext: $baseLineHeight;
