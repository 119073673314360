@import "../global/variables";

.phaeno-workshops {
	table-layout: fixed;
	color: $colorTextSecond;

	th {
		text-align: left;
		color: $colorByNameRed;
	}

	&__head {
		padding-bottom: $baseSize;
		margin-bottom: $baseSize;
		border-bottom: 4px solid $colorByNameRed;
	}

	&__col {
		padding: $baseSize4 0;

		&--icon {
			width: 32px;
			vertical-align: top;
			padding-top: 0
		}

		&--big {
			width: 100%;

			@include breakpoint($breakMenu) {
				width: 47%;
			}
		}

		&--small {
			width: 10%;
		}

		&--x-small {
			width: 3%;
		}

		&--mid {
			width: 30%;
		}

		&--hidden {
			width: 0;
		}

		&--dispensable {
			display: none;
		}

		@include breakpoint($breakMenu) {
			&--dispensable {
				display: table-cell;
			}
		}
	}
}

.phaeno-workshop {
	&__body {
		height: auto;
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: height;
		@include ease-multiple(opacity $animDuration2 $animEaseOut, max-height $animDuration2 $animEaseOut);

		&.active {
			max-height: 1000px;
			opacity: 1;
		}
	}

	&__button {
		color: $colorByNameRed;

		&.active {
			.phaeno-accordion__icon {
				transform: rotate(-90deg);
			}
		}
	}

	&__textBlock {
		padding: $baseSize2 0 0 0;
	}

	&__slider {
		max-width: $maxTextWidth;
	}

	&__close {
		display: none;
	}

	&__head.active &__close {
		display: block;
	}
}
