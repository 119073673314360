.phaeno-luma {

	&__image {
		width: 100%;
		padding-bottom: 56%;
		background: no-repeat;
		background-color: #333;
		background-size: 200%;
		background-position: top left;

		&--img1 {
			background-position: top left;
		}
		&--img2 {
			background-position: top right;
		}
		&--img3 {
			background-position: bottom left;
		}
		&--img4 {
			background-position: bottom right;
		}
	}

}
