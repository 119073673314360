$baseSizeLarge: 11rem;

$baseSize: 4rem;
$baseSize2: 2rem;
$baseSize3: 1.5rem;
$baseSize4: 1rem;
$baseSize6: 0.75rem;
$baseSize8: 0.5rem;
$baseSize16: 0.25rem;

$maxWidth: 1200px;
$maxTextWidth: 690px;
$maxHeaderHeight: 700px;
$minHeaderHeight: 350px;
$minSectionHeight: $baseSize * 8;

$bulletWidth: $baseSize8;
$bulletWidthNarrow: 0.3rem;

$coverImageProportion: 56%;
$sliderImageProportion: 67%;
$teaserBoxProportion: 67%;
$mediaBoxProportion: 67%;

$teaserLogoHeight: 55px;

$backgroundCornerRadius: 0.9rem;

$mainNavIconSize: 2.7rem;

$arrowIconSize: 2.4rem;
$arrowNextPrevIconSize: 3.4rem;

$infoBoxTabSize: 12rem;
$infoBoxTabSizeMobile: 13rem;
$infoBoxIconSize: 4.1rem;

$infoBoxTabSizeSmall: 6rem;
$infoBoxIconSizeSmall: 3.5rem;

$logoWidth: 11.5rem;
$logoHeight: 14rem;

$videoPlaySize: 14rem;

$calendarLeftColumnWidth: 8rem;
$calendarEventHeight: 2.3rem;

$footerLogoHeight: 5rem;
$footerSocialIconSize: 2.7rem;

$headlinksLanguageIconSize: 2rem;

