.phaeno-showAlpha {
	@include ease(opacity, $animDuration);
	transition-delay: 0s;
	//opacity: 0;
}
.phaeno-showable.show {
	.phaeno-showAlpha {
		opacity: 1;
	}
}
