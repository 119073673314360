.phaeno-subpageNav {
	margin-top: $baseSize2;
	margin-bottom: $baseSize2;

	&__nav {
		margin-top: -$baseSize4;
		margin-bottom: $baseSize;
	}

	&__item {
		@include fontSizeHeadSmall;
		@include sansSerif;
		position: relative;
		display: inline-block;
		margin-right: $baseSize2;
		margin-top: $baseSize8;
		margin-bottom: 0;
		vertical-align: middle;
		white-space: nowrap;

		&:hover {
			color:$colorByNameGrey;
		}
		&--active {
			color:$colorByNameGrey;
		}
		&--secondary {
			@include fontSizeCopy;
		}
	}

	&__item:after {
		content: ".";
	}

	&__itemLink {
		border-bottom: none;
	}

	&__line {
		display: block;
		background-color: $colorText;
		margin-top: $baseSize8;
		margin-bottom: $baseSize8;
		width: 150px;
		height: $bulletWidth;
		border-radius: $bulletWidthNarrow;
	}

}

.phaeno-subpageNav__nav + .phaeno-subpageNav__nav {
	.phaeno-subpageNav__item {
		@include sansSerifLight;
	}
}
