.phaeno-additionalMenu {
	background-color: $colorBg;

	margin-top: $baseSize2;
	padding-top: 0;
	padding-bottom: $baseSize2;

	text-align: left;

	@include breakpoint($breakMedium) {
		text-align: right;
		padding-top: $baseSize2;
		border-top: 1px solid $colorBgChoosen;
	}

	&__head {
		color: $colorTextSecond;
		padding-bottom: $baseSize4;
	}
}
