.phaeno-accordion {
	&__head {

	}

	&__body {
		height:auto;
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: height;
		@include ease-multiple(opacity $animDuration2 $animEaseOut, max-height $animDuration2 $animEaseOut);

		&.active {
			max-height: 1000px;
			opacity: 1;
		}
	}

	&__icon {
		display: inline-block;
		margin-bottom: 4px;
		vertical-align: middle;
		transform: scale(-1, 1);
		//transform: rotateY();
		width: $arrowIconSize;
		height: $arrowIconSize;
		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}

	&__button {
		cursor: pointer;
		user-select: none;

		&.active {
			.phaeno-accordion__icon {
				transform: rotate(-90deg);
			}
		}
	}
}
