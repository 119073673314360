// Animation
$animDuration16: 0.10s;
$animDuration8: 0.15s;
$animDuration4: 0.30s;
$animDuration2: 0.6s;
$animDuration: 1.2s;
$animDurationLong: 2.4s;
$animDurationLong2: 6s;

$animEase: linear;

$animEaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530); /* easeInQuad */
$animEaseInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);  /* easeInQuint */

$animEaseOut: cubic-bezier(0.165, 0.84, 0.44, 1); /* easeOutQuad */
$animEaseOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);  /* easeOutQuint */

$animEaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
$animEaseInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */

$animDelay: $animDuration;
$animSequenceDelay: $animDuration4;
