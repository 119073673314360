.phaeno-teaserItem {
	transform: translate3d(0, 0, 0);

	&__content {
		width: 100%;
		padding-bottom: $teaserBoxProportion;

		position: relative;
		overflow: hidden;
	}

	&__imageContainer {
		background-color: $colorByNameGreyLighter;
		position: absolute;
		top: 0;
		left: 0;
		width: 99%;
		height: 99%;
		overflow: hidden;
	}

	&__image {
		position: relative;
		width: 100%;
		height: 100%;
		@include ease(transform, $animDuration2, $animEaseInOut, $animDuration8);
	}

	&__info {
		background-color: $colorBgAlpha;
		color: $colorTextSecond;
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		bottom: 0;
		padding: $baseSize8 $baseSize4;
		padding-bottom: 0;
	}
	&__infoHead {
		font-weight: bold;
		margin-bottom: 0;
	}
	&__infoSubHead {
		margin-bottom: 0;
		@include fontSizeCopySmall;
	}
	&__infoCopy {
		height: 0;
		overflow: hidden;
		@include ease(height, $animDuration4, $animEaseIn, 0);
		margin-top: 0;
		margin-bottom: $baseSize8 !important;
	}
	&:hover {
		.phaeno-teaserItem__infoCopy {
			height: 140px;
			@include ease(height, $animDuration2, $animEaseInOut, $animDuration8);
		}
		.phaeno-teaserItem__image {
			transform: scale(1.05, 1.05);
		}
	}

	&--text {
		.phaeno-teaserItem__content {
			width: 99%;
			background-color: $colorBgHighlighted;
			color: $colorTextHighlighted;
		}
		&__texts {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: $baseSize3;
		}
		&__head {
			margin-bottom: $baseSize8;
			color: $colorTextHighlighted;
			@include fontSizeHead;
		}
		&__copy {
			margin-bottom: $baseSize8;
			color: $colorTextHighlighted;
			font-weight: bold;
			@include fontSizeHeadSmall;
		}
		&__copysmall {
			margin-bottom: $baseSize8;
			@include fontSizeCopy;
			p {
				color: $colorTextHighlighted;
			}
		}

		&__footer {
			background-color: $colorBgHighlightedSecond;
			position: absolute;
			width: 100%;
			height: auto;
			left: 0;
			bottom: 0;
			padding: $baseSize4;
		}

		&__footerText {
			@include fontSizeHeadSmall;
			@include sansSerifBold;
			text-transform: uppercase;
			color: $colorTextHighlighted;
		}
	}


	&__iframe {

		border: 0 !important;

		&:hover .phaeno-teaserItem__infoCopy {
			height: 0 !important;
		}

		.phaeno-teaserItem {
			&__content {
				padding-bottom: calc(67% + 35px);
			}

			&__imageContainer {
				height: calc(99% - 35px);
			}

			&__infoHead {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&__info {
				padding: .5rem 1rem !important;
				max-height: 50%;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}

		.phaeno-teaserItem__info:hover .phaeno-teaserItem {
			&__infoHead {
				overflow: visible;
				white-space: normal;
			}

			&__infoCopy {
				height: auto !important;
			}
		}

	}

}
