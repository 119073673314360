.phaeno-infoBox {
	margin-bottom: 2rem;

	&__head {
		display: inline-block;
		width: 100%;
		margin-top: $baseSize4;

		@include breakpoint($breakMedium) {
			margin-top: $baseSize4 * 3;
			width: 28%;
		}
	}

	&__label {
		text-transform: uppercase;
	}

	&__headText {
		@include fontSizeHeadSmall;
		@include sansSerifBold;
		margin-bottom: $baseSize8;
		color: $colorText;
		display: block;

		@include breakpoint($breakSmall) {
			display: inline-block;
			padding: 0 $baseSize4;
		}

		@include breakpoint($breakMedium) {
			display: block;
			padding: 0;
		}
	}

	&__headSubtext {
		@include fontSizeCopy;
		@include sansSerifMedium;
		color: $colorText;
		display: block;

		@include breakpoint($breakSmall) {
			display: inline-block;
			padding: 0 $baseSize4;
		}

		@include breakpoint($breakMedium) {
			display: block;
			padding: 0;
		}
	}

	&__menu {
		text-align: center;
		@include breakpoint($breakMedium) {
			text-align: left;
		}
	}

	&__nav {
		display: inline-block;
		width: 100%;

		@include breakpoint($breakMedium) {
			width: 72%;
			float: right;
			text-align: right;
		}
	}

	&__content {
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	&__contentItem {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $colorBgChoosen;
	}
}
