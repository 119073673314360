.phaeno-mainNav {
	display: block;
	max-width: $maxWidth;
	margin: auto;

	&__hamburger {
		display: block;
		@include breakpoint($breakMenu) {
			display: none;
		}
		margin-top: $baseSize2;
		margin-bottom: $baseSize4;

		margin-left: $baseSize4;
		@include breakpoint($breakSmall) {
			margin-left: 0;
		}
	}

	&__items {
		opacity: 1;
		display: none;
		margin-top: $baseSize2;
		margin-bottom: 1.3rem;
		padding-right: 0;
		z-index: 2;
		@include breakpoint($breakMenu) {
			display: block;
			padding-right: $logoWidth - $baseSize2;
		}

		margin-left: $baseSize4;
		@include breakpoint($breakSmall) {
			margin-left: 0;
		}

		//hide on international onepagers
		.phaeno-uid-109 & {
			visibility: hidden;
		}
		.phaeno-uid-111 & {
			visibility: hidden;
		}
	}

	&.open .phaeno-mainNav__items {
		display: block;
	}

	&__item {
		@include fontSizeHeadSmall;
		@include sansSerifMedium;
		position: relative;
		display: inline-block;
		margin-right: $baseSize2;
		vertical-align: bottom;
		white-space: nowrap;

		margin-bottom: $baseSize4;
		@include breakpoint($breakMenu) {
			margin-bottom: 0;
		}

		&:hover {
			color:$colorByNameGrey;
		}
		&--active {
			color:$colorByNameGrey;
		}
	}
	&__item:after {
		content: ".";
	}

	&__item--icon {
		width: $mainNavIconSize;
		height: $mainNavIconSize;
		svg {
			width: $mainNavIconSize;
			height: $mainNavIconSize;
		}
	}
	&__item--icon:before {
		display: none;
	}

	&__itemLink {
		border-bottom: none;
	}

	&__logo {
		pointer-events: none;
		position: absolute;
		right: $baseSize8;
		top: -$baseSize4;
		width: $logoWidth;
		height: $logoHeight;

		@include breakpoint($breakSmall) {
			right: $baseSize8 * 3;
		}
	}
}
