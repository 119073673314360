.phaeno-containerThirds {
	margin-left: -$baseSize4;
	margin-right: -$baseSize4;

	@include clearfix;
	@include breakpoint($breakMedium) {
		@include clearfixdelete;
	}

	&--inline {
		.phaeno-containerThirds__item {
			float: none;
			margin-left: -4px;
		}
	}

	&__item, &__item1-3, &__item2-3 {
		position: relative;
		display: inline-block;
		float: left;
		vertical-align: top;

		margin-top: $baseSize4;
		margin-bottom: $baseSize4;
		padding-left: $baseSize4;
		padding-right: $baseSize4;
	}
	@include breakpoint($breakSmall ) {
		&__item, &__item1-3, &__item2-3 {
			padding-right: $baseSize4;
		}
	}

	&__item {
		width: span(12);
		@include breakpoint($breakSmall ) {
			width: span(6);
		}
		@include breakpoint($breakMedium) {
			width: span(4);
		}
	}

	@include breakpoint($breakMedium) {
		//smaller space between 1-3 and 2-3
		&__item1-3 + &__item2-3 {
			//padding-left: 0;
		}
		&__item2-3 + &__item1-3 {
			//padding-left: 0;
		}
	}

	&__item1-3 {
		width: span(12);
		@include breakpoint($breakMedium) {
			width: span(4);
		}
	}

	&__item2-3 {
		width: span(12);
		@include breakpoint($breakMedium) {
			width: span(8);
		}
	}
}
