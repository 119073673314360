.phaeno-hamburger {

	width: $mainNavIconSize;
	height: $mainNavIconSize;

	span {
		display: block;
		position: relative;

		height: .4rem;

		background-color: $colorText;
		margin-top: .4rem;
		margin-bottom: .4rem;
		border-radius: .2rem;

		@include ease(all, $animDuration4);
		width: 100%;

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(3) {
			bottom: 0;
		}
	}

	.js-phaeno-mainNav.open & {
		span {
			transition-delay: 0;
			&:nth-child(1) {
				transform: rotate(45deg);
				top: $baseSize8 + 0.3rem;
			}
			&:nth-child(2) {
				width: 0;
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
				bottom: $baseSize8 + 0.3rem;
			}
		}
	}
}
