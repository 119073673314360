.phaeno-teaserLogo {

	transform: translate3d(0, 0, 0);

	&__content {
		margin-bottom: $baseSize2;
		max-width: 320px;
	}

	&__imageContainer {
		position: relative;
		padding-bottom: 33%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: $baseSize2;
	}

	&__image {
		position: absolute;
		height: 100%;
		width: auto;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	&__info {
		color: $colorTextSecond;
		width: 100%;
		padding: $baseSize8 $baseSize4;
	}

	&__infoHead {
		font-weight: bold;
		margin-bottom: 0;
		text-align: center;
	}
	&__infoCopy {
		margin-top: $baseSize8;
		margin-bottom: $baseSize8 !important;
		text-align: center;
	}
}
