@import "../global/variables";

.phaeno-deflist {
	color: $colorTextSecond;
	overflow: hidden;

	&__term {
		display:inline-block;
		color: $colorText;
		width:20%;
		min-width: 12em;
		float:left;
		clear:left;
		@include sansSerif;
	}

	&__definition {
		color: $colorTextSecond;
		float:left;
		width:calc(80% - 1em);
		margin-left:0;
		margin-bottom: $baseSize8;
	}
}
