@mixin ease($property: all, $ease-d: $animDuration2, $ease-f: $animEaseOut, $ease-delay: 0s) {
	transition-property: $property;
	transition-duration: $ease-d;
	transition-timing-function: $ease-f;
	transition-delay: $ease-delay;
}

@mixin ease-multiple($properties...) {
	transition: $properties;
}

@mixin ease-clear() {
	transition: none;
}
