.phaeno-showAlphaUp {
	@include ease-multiple(opacity $animDuration $animEaseOut, transform $animDuration $animEaseOut );
	transition-delay: 0s;
	//opacity: 0;
	//transform: translate(0, 30px);
}
.phaeno-showable.show {
	.phaeno-showAlphaUp {
		opacity: 1;
		transform: translate(0, 0);
	}
}
.phaeno-animDelayed {
	.phaeno-showAlphaUp {
		transition-delay: $animDelay;
	}
}
