@import "../global/variables";

.phaeno-downloads  {

	max-width: $maxTextWidth;

	&__item {
		margin-bottom: $baseSize2;
	}
}

.phaeno-download  {
	&__title {
		font-weight: bold;
	}
	&__title--meta {
		font-size: 75%;
		opacity: 0.75;
	}
}
