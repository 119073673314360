// `rote hinweisbox`
.phaeno-article__layout--11 {
	$b: &;

	$content-width: 400px;
	$content-vmargin: 25px;

	position: relative;
	padding: {
		top: $content-vmargin;
		bottom: 0;
	}

	@include breakpoint(0 $breakSmall - 1) {
		margin: {
			left: 0;
			right: 0;
		}
	}

	@include breakpoint($breakSmall $breakMedium - 1) {
		margin: {
			left: $baseSize;
			right: $baseSize;
		}
	}

	@include breakpoint($breakMedium) {
		margin: {
			left: $baseSize + $baseSizeLarge;
			right: $baseSize;
		}
	}

	&__content {
		width: 100%;
		margin: {
			left: auto;
			right: auto;
		}
		background-color: $colorByNameRed;
		display: flex;
		flex-direction: column;
	}

	&__content-header {
		padding: 15px 20px 2px 20px;
		background-color: $colorByNameRedDark;
		flex-grow: 0;
		flex-shrink: 0;

		&, h2 {
			@include fontSizeHead;
			@include sansSerifMedium;
			color: #fff;
		}
	}

	&__content-text {
		padding: 20px;
		background-color: $colorByNameRed;
		flex-grow: 1;
		flex-shrink: 1;
		max-width: 690px;

		&, p {
			color: #fff;

			a {
				border-bottom: 1px solid;
			}
		}
	}

	&--with-bg-img {
		padding: {
			top: 0;
			bottom: 0;
		}

		#{$b}__content {
			max-width: $content-width;
			position: absolute;
			left: 0;
			right: 0;
			top: $content-vmargin;
			bottom: $content-vmargin;
		}

		#{$b}__content-text {
			overflow-y: auto;
		}

		#{$b}__bg-img {
			display: block;

			img {
				display: block;
			}
		}
	}
}
