$stepsHeight: 50px;

.phaeno-shop {
	color: $colorTextSecond;

	&__label {
		@include fontSizeCopySmall;
		@include sansSerifLight;
		color: $colorTextLight;
		font-weight: normal;
		text-transform: uppercase;
		clear: both;
	}
	&__text {
		@include fontSizeCopy;
		font-weight: normal;

		@include breakpoint($breakMedium) {
			width: 75%;
		}

		&--small {
			@include fontSizeCopySmall;
		}
		&--nowrap {
			white-space: nowrap;
		}
	}
	&__action {
		color: $colorText;
		padding-left: $baseSize4;
	}
	&__button {
		@include fontSizeCopy;
		border: none;
		background-color: $colorByNameRed;
		padding: $baseSize8 $baseSize;
		border-radius: $baseSize4;
		color: $colorTextHighlighted;

		&--right {
			float: right;
		}
	}
	&__fieldset {
		background-color: $colorByNameGreyLightest;
		border: none;
		position: relative;
		padding-top: $baseSize * 1.5;
		margin: 0 0 $baseSize2 0;
	}
	&__legend {
		position: absolute;
		top: $baseSize4;
		left: $baseSize4;
	}

	&__steps {
		margin-top: $baseSize;
	}
	&__step-list {
		list-style: none;
		background-color: $colorByNameGreyLightest;
		display: table;
		width: 100%;
		height: $stepsHeight;
	}
	&__step-listitem {
		display: table-cell;
	}

	&__step {
		padding: $baseSize4 $baseSize2;
		@include breakpoint($breakMedium) {
			padding: $baseSize6 $baseSize2;
		}
		text-align: center;
		display: inline-block;
		position: relative;
		line-height: 120%;
		width: 100%;
		height: $stepsHeight;

		@include breakpoint($breakSmall) {
			width: 80%;
		}
	}
	&__step-listitem {
		&--active {
			.phaeno-shop__step {
				background-color: $colorByNameGreyLight;
				color: white;
			}
			@include breakpoint($breakSmall) {

				&:not(:last-child):after {
					content: "";
					width: 0;
					height: 0;
					border-top: $stepsHeight/2 solid transparent;
					border-bottom: $stepsHeight/2 solid transparent;
					border-left: $stepsHeight/2 solid $colorByNameGreyLight;
					display: inline-block;
					position: absolute;
					text-align: center;
				}
			}
		}
	}

	&__catalog {
		margin: $baseSize 0;
	}
	&__catalog-table {
		background-color: $colorByNameGreyLightest;
		margin-bottom: $baseSize2;
	}
	&__column {
		&--item {
			width: 100%;
		}
		&--price {
			width: 15%;
		}
		&--number {
			width: 15%;
		}
		&--sum {
			width: 10%;
		}
	}
	&__row:nth-child(even) {
		background-color: $colorByNameGreyLighter;
	}
	&__th {
		text-align: left;
		background-color: white;
	}
	&__td {
		padding: $baseSize4;
		vertical-align: top;
	}
	&__category {
		@include sansSerifBold;
		text-transform: uppercase;
		padding-top: $baseSize4;
	}

	&__account,
	&__payment {
		margin: $baseSize 0;
	}
	&__inputandlabel,
	&__checkboxandlabel {
		width: 100%;
		display: inline-block;
		margin: 0 $baseSize2 $baseSize4 0;

		@include breakpoint($breakSmall) {
			width: 80%
		}
		@include breakpoint($breakMenu) {
			width: 40%;
		}
	}

	&__checkboxandlabel {
		width: 100%;
	}
	&__account-label {
		@include fontSizeCopySmall;
		display: block;

		&--checkbox {
			display: inline;
		}

		a {
			color: $colorText;
		}
	}
	&__account-input {
		@include fontSizeCopy;
		display: block;
		width: 100%;
		margin-top: 0;
		padding: $baseSize8 $baseSize4 0;
		border: 1px solid $colorByNameGreyLighter;

		&--nr {
			width: 5rem;
		}
		&--plz {
			width: 10rem;
		}
	}
	&__account-checkbox {
		display: inline;
	}

	&__provider {
		width: 40%;
		display: inline-block;
		margin: 0 $baseSize2 $baseSize4 0;

		@include breakpoint($breakSmall) {
			width: 40%
		}
		@include breakpoint($breakMenu) {
			width: 20%;
		}
	}
	&__provider-image {
		width:100%;
		height: auto;
	}

	&__embed {
		display: block;
		position: relative;
		padding: 0;
		height: 1670px;
		width:100%;
		overflow: hidden;
		border:none;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border:none;
		}
	}
}
