@import "../global/variables";

.phaeno-ce {
	.phaeno-slider,
	.phaeno-image {
		margin: $baseSize2 0;
	}

	&--above {
		.phaeno-slider,
		.phaeno-image {
			margin-bottom: $baseSize;
		}
	}

	&--below {
		.phaeno-slider,
		.phaeno-image {
			margin-top: $baseSize;
		}
	}
}
