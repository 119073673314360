.phaeno-cleverreach {

	.cr_site {
		margin: 0;
		padding: 75px 0 0 0;
		text-align: center;
		background-color: #eeeeee;
	}

	.cr_font {
		@include fontSizeCopy;
	}

	.cr_body h2, .cr_header h2 {
		font-size: 22px;
		line-height: 28px;
		margin: 0 0 10px 0;
	}

	.cr_body h1, .cr_header h2 {
		font-size: 28px;
		margin-bottom: 15px;
		padding: 0;
		margin-top: 0;
	}

	.wrapper, .cr_page {
		margin: 0 auto 10px auto;
		text-align: left;
		border-radius: 4px;
	}

	.cr_header {
		text-align: center;
		background: transparent !important;
	}

	.cr_body label {
		float: none;
		clear: both;
		display: block;
		width: auto;
		margin-top: 8px;
		text-align: left;
		font-weight: bold;
		position: relative;
	}

	.cr_button {
		display: inline-block;
		font-family: 'Helvetica', Arial, sans-serif;
		width: auto;
		white-space: nowrap;
		height: 32px;
		margin: 5px 5px 0 0;
		padding: 0 22px;
		text-decoration: none;
		text-align: center;
		font-weight: bold;
		font-style: normal;
		font-size: 15px;
		line-height: 32px;
		cursor: pointer;
		border: 0;
		-moz-border-radius: 4px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		vertical-align: top;
	}

	.cr_button {
		background-color: #333;
		color: #ffffff;
	}

	.cr_button:hover, .cr_button-small:hover {
		opacity: 0.7;
		filter: alpha(opacity=70);
	}

	.powered {
		padding: 20px 0;
		width: 560px;
		margin: 0 auto;
	}

	.formbox {
		@include fontSizeCopy;
		color: #333333;
		padding: 20px;
		background-color: #ffffff;
		border-radius: 6px 6px 6px 6px;
	}

	.cr_ipe_item label {
		line-height: 150%;
		font-size: 14px;
	}

	.cr_ipe_item textarea {
		background: none repeat scroll 0 0 #eeeeee;
		border: 1px solid #aaa;
		font-family: Helvetica;
		font-size: 16px;
	}

	.cr_ipe_item input {
		background: none repeat scroll 0 0 #eeeeee;
		border: 1px solid #aaa;
		padding: 5px;
		font-family: Helvetica;
		font-size: 16px;
		width: 300px;
	}

	.cr_ipe_item select {
		background: none repeat scroll 0 0 #eeeeee;
		border: 1px solid #aaa;
		display: block;
		margin: 0;
		padding: 5px;
		width: 100%;
		font-family: Helvetica;
		font-size: 16px;
	}

	.cr_ipe_item input.cr_ipe_radio, input.cr_ipe_checkbox {
		-moz-binding: none;
		-moz-box-sizing: border-box;
		background-color: -moz-field !important;
		border: 2px inset threedface !important;
		color: -moz-fieldtext !important;
		cursor: default;
		height: 13px;
		padding: 0 !important;
		width: 13px;
	}

	.cr_ipe_item input.cr_ipe_radio {
		-moz-appearance: radio;
		border-radius: 100% 100% 100% 100% !important;
		margin: 3px 3px 0 5px;
	}

	.submit_container {
		//text-align: center
	}

	.cr_ipe_item {
		padding: 0 10px 20px 10px;
		margin: 1px 10px;
	}

	.cr_ipe_item.inactive {
		display: none;
	}

	.imprint {
		font-size: 0.8em;
	}

	.cr_captcha {
		padding-left: 130px;
	}

	.cr_error {
		font-size: 1.1em;
		padding: 10px;
	}

	.clever_form_error {
		background-color: #f99;
		color: #000;
		border: 1px solid #f22 !important
	}

	.clever_form_note {
		margin: 26px 0 0 3px;
		position: absolute;
		display: inline;
		padding: 2px 4px;
		font-weight: bold;
		background-color: #f2ecb5;
		color: #000;
		font-size: 12px !important;
	}

	.cr_site {
		background-color: #eee;
	}

	.cr_header {
		color: #000000;
	}

	.cr_body {
		background-color: #ffffff;
		font-size: 12px;
		color: #000000;
	}

	.cr_hr {
		background-color: #ccc;
	}

	.cr_site a {
		color: #0084ff;
	}

	.imprint {
		color: #000;
	}

	.cr_site {
		background-color: #ffffff;
	}

	.cr_body {
		color: #000000;
		background-color: #ffffff;
	}

	.cr_header {
		color: #E40038;
	}

	.cr_hr {
		background-color: #ccc;
	}

	.cr_site a {
		color: #E40038;
	}

	.imprint {
		color: #000000;
	}


	.cr_font {
		color: #E40038;
	}
}