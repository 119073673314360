.phaeno-bookmark {
	position: absolute;
//	top: 0;
	//width: 0;

	//height: 0;

	right: -$baseSize2;
	@include breakpoint($breakSmall) {
		right: -$baseSize4 * 3;
	}

	display: none;
	@include breakpoint($breakSmall) {
		display: block;
	}

	&__text {
		position: relative;
		display: block;
		width: auto;

		transform: rotate(90deg) translateX(100%);
		transform-origin: right top 0;

		color: $colorText;
		margin-bottom: 0;
		padding-bottom: 0;
		@include sansSerifMedium;
		//white-space: nowrap;
		letter-spacing: 0.5px;
	}

	&__text:before {
		content: "";
		position: absolute;
		background-color: $colorText;
		left: -$baseSize4;
		top: 0;
		width: $bulletWidth;
		height: 100%;
		border-radius: $bulletWidthNarrow;
	}
}
