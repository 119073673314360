
$calendarHorizontalPadding: 3rem;

.phaeno-calendarSimple {
	position: relative;
	z-index: 1;

	&__head {
		position: absolute;
		top: -4rem;
		left: 0;
		margin-top: $baseSize4;
		margin-left: $baseSize4;
		margin-right: $baseSize4;

		@include fontSizeCopy;
		@include sansSerif;
		color: $colorByNameGrey;
		font-weight: bold;

		&--week {
			color: $colorByNameGreyLight;
			font-weight: normal;
			display: none;
		}
		@include breakpoint($breakSmall) {
			&--week {
				display: inline;
			}
		}
	}
	.phaeno-calendar {
		height: 47rem;
		padding: $baseSize4;
		border: 2px solid #ccc;
		border-radius: 3px;

		&--noborder {
			height: 45rem;
			border: none;
			padding: 0;

			.phaeno-calendarSimple__head {
				margin-left:0;
			}
			.phaeno-calendar__axe {
				display: none;
			}
		}
	}

	&__prev,
	&__next {
		width: 100%;
		height: $arrowNextPrevIconSize;
		text-align: center;

		svg {
			width: $arrowNextPrevIconSize;
			height: $arrowNextPrevIconSize;
		}
	}

	&__linkAll {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0;
		background-color: $colorBgHighlighted;
		color: $colorTextHighlighted;
		padding: 2px 10px 0 10px;
		border-radius: 2rem;
		&:hover {
			background-color: $colorBgHighlightedSecond;
		}
	}

	&__linkAllIcon {
		display: inline-block;
		margin-bottom: 4px;
		vertical-align: middle;
		transform: scale(-1, 1);
		width: $arrowIconSize;
		height: $arrowIconSize;

		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}


	&--events {
		.phaeno-calendar {
			height: auto;
		}

		.phaeno-containerFull__text.phaeno-textBlock,
		.phaeno-calendar__event--open,
		.phaeno-calendarSimple__linkAll,
		.phaeno-calendarSimple__next,
		.phaeno-calendarSimple__prev {
			display: none !important;
		}

		.phaeno-calendar__month {
			margin-bottom: 0;
		}

		.phaeno-calendar__day .phaeno-calendar__axe {
			display: none;
		}

		.phaeno-calendar__day--active .phaeno-calendar__axe {
			display: block;
		}

	}
}
