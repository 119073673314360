@import "../global/variables";

.phaeno-list {
	color: $colorTextSecond;

	&--unordered {
		list-style-type: disc;
	}
	&--ordered {
		list-style-type: decimal;
	}
	&__item {
		margin-left: 2rem;
	}

}
