$colorByNameBlack: #000000;
$colorByNameWhite: #ffffff;
$colorByNameRed: #E40038;
$colorByNameRedDark: #b41b2a;
$colorByNameBlue: #4a90e2;
$colorByNameGrey: #4b4b4b;
$colorByNameGreyLight: #898989;
$colorByNameGreyLighter: #e9e9e9;
$colorByNameGreyLightest: #f0f0f0;

$colorBg: $colorByNameWhite;
$colorBgChoosen: $colorByNameGreyLighter;
$colorBgHover: $colorByNameGreyLightest;
$colorText: $colorByNameRed;
$colorBgSecond: $colorByNameGrey;
$colorInactive: $colorByNameGreyLight;
$colorTextSecond: $colorByNameGrey;
$colorTextAdditional: $colorByNameBlue;
$colorTextLight: $colorByNameGreyLight;

$colorTextHighlighted: $colorByNameWhite;
$colorBgHighlighted: $colorByNameRed;
$colorBgHighlightedSecond: $colorByNameRedDark;

$colorAlpha: 0.85;
$colorBgAlpha: rgba($colorByNameWhite, $colorAlpha);
