* {
	box-sizing: border-box;
}

html, body{
	@include sansSerif;
	width: 100%;
	height: 100%;

	color: $colorText;
}

html {
	font-size: $fontBase;
}

body {
	@include fontSizeCopy;
	overflow-y: scroll;
	overflow-x: hidden;
	max-width: 100%;
}

p {
	margin-bottom: $baseSize4;
}
p:last-of-type {
	margin-bottom: 0;
}

h1 {
	@include fontSizeHuge2;
	@include sansSerifBlack;
	text-transform: uppercase;
}

h2 {
	@include fontSizeHead;
	@include sansSerifBlack;
}
h3 {
	@include fontSizeHeadSmall;
	font-weight: normal;
}

h4 {
	@include fontSizeHeadSmall;
}

h5 {
	@include fontSizeCopy;
}

p {
	@include fontSizeCopy;
	color: $colorTextSecond;
	font-weight: normal;
}

a {
	cursor: pointer;
	text-decoration: none;
}

hr {
	margin-top: $baseSize2;
	margin-bottom: $baseSize2;
	color: $colorBgSecond;
}
