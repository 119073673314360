.phaeno-textTabMenu {

	&__nav {
		margin-top: 0;
		transform:translate3d(0,0,0);
	}

	&__button {
		position: relative;
		color: $colorText;
		text-align: left;
		margin-bottom: $baseSize16;
		margin-top: $baseSize16;
	}

	&__button.active {
		margin-bottom: $baseSize4;
		margin-top: $baseSize16;
		color: $colorByNameGrey;
		.phaeno-textTabMenu {
			&__buttonText {
				//@include sansSerifBold;
			}
			&__buttonIcon {
				opacity: 1;
				@include ease(opacity);
			}
			&__buttonAdditionalText {
				height: 10rem;
				opacity: 1;
			}
			&__line {
				width: 100%;
				@include ease(width);
			}
		}
	}

	&__buttonText {
		position: relative;
		display: block;
		width: 100%;
		@include fontSizeHeadSmall;
		@include sansSerifMedium;
	}

	&__line {
		display: block;
		background-color: $colorText;
		margin-top: $baseSize8 + $bulletWidthNarrow;
		margin-bottom: -$baseSize8 + $bulletWidthNarrow*2;
		width: 0%;
		height: $bulletWidthNarrow;
		border-radius: $bulletWidthNarrow;
		@include ease(width);
	}

	&__buttonAdditionalText {
		position: relative;
		display: block;
		overflow: hidden;
		color: $colorTextSecond;
		@include fontSizeCopy;
		@include sansSerifMedium;
		margin-top: $baseSize4;
		height: 0;
		opacity: 0;
		@include ease();
	}

	&__buttonIcon {
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		transform: scale(-1, 1);
		width: $arrowIconSize;
		height: $arrowIconSize;
		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}

	&--bg {
		.phaeno-exclusionContent__bg {
			background-color: $colorBg;
			padding: $baseSize2;
		}
	}

	&--right {
		text-align: right;
	}

	&__paymentInfo {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		padding: 2rem;

		img {
			width: 80%;
		}
	}
}
