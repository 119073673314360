.phaeno-calendarTag {
	position: relative;

	display: inline-block;
	padding: $baseSize8 $baseSize $baseSize8 $baseSize4;
	margin: 0 $baseSize8;

	color: $colorTextHighlighted;
	background-color: $colorBgHighlighted;

	border-radius: $calendarEventHeight;
	font-weight: bold;

	@include fontSizeSubtext;

	&__icon {
		position: absolute;
		right: $baseSize8;
		top: 0;
		width: $arrowIconSize;
		height: $arrowIconSize;
		overflow: hidden;

		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}
}
