@import "../global/variables";

.phaeno-experimente {
	@include clearfix;
	table-layout: fixed;
	color: $colorTextSecond;
	margin-top: $baseSize;

	th {
		vertical-align: top;
		text-align: left;
		color: $colorByNameRed;
	}
	td {
		vertical-align: top;
	}

	&__head {
		padding-bottom: $baseSize;
		margin-bottom: $baseSize;
		border-bottom: 4px solid $colorByNameRed;
	}

	&__col {
		padding: $baseSize4 0;

		&--icon {
			width: 32px;
			vertical-align: top;
			padding-top:0
		}
		&--big {
			width: 75%;

			@include breakpoint($breakMenu) {
				width: 50%;
			}
		}

		&--small {
			width: 10%;
		}

		&--mid {
			width: 25%;
		}

		&--hidden {
			width: 0;
		}

		&--dispensable {
			display: none;
		}
		&--truncate {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		@include breakpoint($breakMenu) {
			&--dispensable {
				display: table-cell;
			}
		}
	}
	.active {
		.phaeno-experimente__col--hideWhenActive {
			display:none;
		}
	}
}

.phaeno-experimenteNav {
	margin-top: $baseSize2;
	margin-bottom: $baseSize2;

	&__nav {
		margin-bottom: $baseSize4;
	}

	&__label {
		@include fontSizeHeadSmall;
		@include sansSerif;
		position: relative;
		display: inline-block;
		margin-right: $baseSize8;
		margin-top: $baseSize8;
		margin-bottom: 0;
		vertical-align: middle;
		white-space: nowrap;
		min-width: 13rem;

		&--secondary {
			@include fontSizeCopy;
		}
	}
	&__form {
		display: inline-block;
	}
	&__input {
		@include fontSizeCopy;
		margin: 0;
		padding: $baseSize8 $baseSize8 0;
		border: 1px solid $colorByNameGreyLighter;
	}
	&__submit {
		@include fontSizeCopySmall;
		border: none;
		background-color: $colorByNameRed;
		padding: $baseSize8 $baseSize4;
		border-radius: $baseSize4;
		color: $colorTextHighlighted;
		margin: 0;
		vertical-align: top;
	}

	&__item {
		@include fontSizeHeadSmall;
		@include sansSerif;
		position: relative;
		display: inline-block;
		margin-top: $baseSize8;
		margin-bottom: 0;
		vertical-align: middle;
		white-space: nowrap;

		&:hover {
			color:$colorByNameGrey;
		}
		&--active {
			color:$colorByNameGrey;
		}
		&--secondary {
			@include fontSizeCopy;
			@include sansSerifLight;
		}
	}

	&__item:not(:last-child):after {
		content: "|";
		color: $colorByNameRed;
	}

	&__itemLink {
		border-bottom: none;
	}

	&__line {
		display: block;
		background-color: $colorText;
		margin-top: $baseSize8;
		margin-bottom: $baseSize8;
		width: 150px;
		height: $bulletWidth;
		border-radius: $bulletWidthNarrow;
	}

}



.phaeno-experiment {
	&__head {

		.active {

		}
	}
	&__body {
		height:auto;
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: height;
		@include ease-multiple(opacity $animDuration2 $animEaseOut, max-height $animDuration2 $animEaseOut);

		&.active {
			max-height: 1000px;
			opacity: 1;
		}
	}
	&__button {
		color: $colorByNameRed;
	}
	&__textBlock {
		padding: 0;
	}
	&__slider {
		max-width: $maxTextWidth;
	}
}
