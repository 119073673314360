.phaeno-headlinks {

	margin-top: $baseSize + $baseSize2;
	position: absolute;
	top:0;
	padding-left: 4rem;
	z-index: 5;
	display: none;

	@include breakpoint($breakMedium) {
		display: block;
	}

	&__languages {
	}

	&__language {
		display: block;
		padding-bottom: $baseSize8;
	}
	&__language :hover {
		opacity: 0.7;
		@include ease(opacity);
	}

	&__languageIcon {
		width: $headlinksLanguageIconSize;
		height: $headlinksLanguageIconSize * (3/4);
		svg {
			width: $headlinksLanguageIconSize;
			height: $headlinksLanguageIconSize * (3/4);
		}
	}

}
