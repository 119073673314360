@import "../global/variables";

.phaeno-article {
	position: relative;
	padding-top: $baseSize * 1.75;

	&--no-head {
		padding-top: $baseSize * 1.75;
		margin-top: - $baseSize * 0.9;
	}

	&__layout {

		// layout: logos
		&--6 {
			padding-top: 0;
			padding-bottom: $baseSize * 1.75;
		}
	}
}
