.tx-pwcomment-pi1 {

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	h1 {
		display: none;
	}

	ul {
		list-style-type: none;
	}


	.comments-list {
		position: relative;
		padding-left: 0 !important;
	}

	.comments-list:before {
		content: '';
		width: 2px;
		height: 100%;
		background: #c7cacb;
		position: absolute;
		left: 32px;
		top: 0;
	}

	.comments-list:after {
		content: '';
		position: absolute;
		background: #c7cacb;
		bottom: 0;
		left: 28px;
		width: 10px;
		height: 10px;
		border: 3px solid #c7cacb;
		border-radius: 50%;
	}

	.comments-list.no-avatar:before {
		display: none;
	}

	.comments-list.no-avatar:after {
		display: none;
	}

	.comments-list.no-avatar .comment-box:before, .comments-list.no-avatar .comment-box:after {
		display: none;
	}

	.comments-list.no-avatar .comment-box {
		float: none;
	}

	.reply-list:before, .reply-list:after {
		display: none;
	}

	.reply-list li:before {
		content: '';
		width: 60px;
		height: 2px;
		position: absolute;
		top: 25px;
		left: -55px;
	}

	.comments-list li {
		margin-bottom: 15px;
		display: block;
		position: relative;
	}

	.comments-list li:after {
		content: '';
		display: block;
		clear: both;
		height: 0;
		width: 0;
	}

	.reply-list {
		padding-left: 88px;
		clear: both;
		margin-top: 15px;
	}

	.comments-list .comment-avatar {
		width: 65px;
		height: 65px;
		position: relative;
		z-index: 99;
		float: left;
		border: 1px solid #fff;
		border-radius: 4px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		overflow: hidden;
		background: #f2f2f2;
	}

	.comments-list .comment-avatar img.default-user-icon {
		padding: 8px 5px 5px;
	}

	.comments-list .comment-avatar img {
		width: 100%;
		height: 100%;
	}

	.reply-list .comment-avatar {
		width: 50px;
		height: 50px;
	}

	.comment-main-level:after {
		content: '';
		width: 0;
		height: 0;
		display: block;
		clear: both;
	}

	.comments-list .comment-box {
		width: auto;
		float: right;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	}

	.comments-list .comment-box:before, .comments-list .comment-box:after {
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		display: block;
		border-width: 10px 12px 10px 0;
		border-style: solid;
		border-color: transparent #F2F2F2;
		top: 12px;
		left: -11px;
	}

	.comments-list .comment-box:before {
		border-width: 11px 13px 11px 0;
		border-color: transparent rgba(0, 0, 0, 0.05);
		left: -12px;
	}

	.reply-list .comment-box {
		width: 610px;
	}

	.comment-box .comment-head {
		background: #F2F2F2;
		padding: 14px 12px;
		border-bottom: 1px solid #E5E5E5;
		overflow: hidden;
		border-radius: 4px 4px 0 0;
	}

	.comment-box .comment-head i {
		float: right;
		margin-left: 14px;
		position: relative;
		top: 2px;
		color: #A6A6A6;
		cursor: pointer;
		-o-transition: color 0.3s ease;
		transition: color 0.3s ease;
	}

	.comment-box .comment-head i:hover {
		color: #03658c;
	}

	.comment-box .comment-name {
		color: #283035;
		font-weight: bold;
		float: left;
		margin: 0 10px 0 0;
	}

	.comment-box .comment-date {
		line-height: 1.1;
	}

	.comment-box .comment-name a {
		color: #283035;
	}

	.comment-box .comment-head span {
		float: left;
		color: #999;
		font-size: 13px;
		position: relative;
		top: 1px;
	}

	.comment-box .comment-content {
		background: #FFF;
		padding: 12px;
		font-size: 15px;
		color: #595959;
		border-radius: 0 0 4px 4px;
	}

	.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {
		color: #03658c;
	}

	.comment-box .comment-name.by-author:after {
		content: 'autor';
		background: #03658c;
		color: #FFF;
		font-size: 12px;
		padding: 3px 5px;
		font-weight: 700;
		margin-left: 10px;
		border-radius: 3px;
	}

	.comment-actions {
		position: absolute;
		right: 12px;
		top: 10px;
	}

	.comment-actions .votesum {
		font-size: 1.1em !important;
		color: #000 !important;
		margin-right: 5px;
	}

	.comment-actions a.upvote,
	.comment-actions a.downvote,
	.comment-actions a.reply {
		margin: 0 2px;
	}

	.comment-actions a.reply {
		margin-left: 25px;
		vertical-align: text-bottom;
	}

	.typo3-messages {
		padding-left: 0 !important;
	}

	.alert {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
	}

	.alert-success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}

	.alert-danger {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}

	#thanksForYourComment, #customMessages {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	#thanksForYourComment li, #customMessages li {
		background: #ccffcc;
		border: 1px solid #009900;
		color: #009900;
		font-weight: bold;
		padding: 6px 10px;
		margin-bottom: 15px;
	}

	#customMessages li {
		background: #ffcccc;
		border-color: #990000;
		color: #990000;
	}

	/** Formular **/

	form {

		.hide_initally {
			display: none !important;
		}

		.button {
			display: inline-block;
			font-family: Helvetica, Arial, sans-serif;
			width: auto;
			white-space: nowrap;
			height: 32px;
			margin: 5px 5px 0 0;
			padding: 0 22px;
			text-decoration: none;
			text-align: center;
			font-weight: 700;
			font-style: normal;
			font-size: 15px;
			line-height: 32px;
			cursor: pointer;
			border: 0;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			vertical-align: top;
			background-color: #333;
			color: #fff;
		}

		textarea, input[type="text"], select {
			background: none repeat scroll 0 0 #eee;
			border: 1px solid #aaa;
			padding: 0.5em;
			font-family: Helvetica, Arial, serif;
			font-size: 16px;
			width: 100% !important;
		}

		label {
			margin: 0.6em 0 0.2em !important;
			display: block
		}

		input[type="checkbox"] {
			margin: 1.5em 1em 0 0;
		}

		input[type="submit"] {
			margin-top: 0.5em;
		}

		a {
			display: block;
			text-align: center;
			font-weight: bold;
			padding: 0.8em 0;

			&:hover {
				text-decoration: underline !important;
				border: 0 !important;
			}
		}
	}
}
