.phaeno-style-3 {

	main {
		color: #000 !important;

		header, h1, h2, h3, a {
			color: #000 !important;
		}
	}

	.phaeno-logo__slogan,
	.phaeno-bookmark,
	.phaenos-header,
	.phaeno-mainNav__items li {
		display: none;
	}

	.phaeno-subpageNav__nav {
		margin-bottom: 1.6rem !important;
	}

	.phaeno-header {
		border-bottom: 0 !important;
	}

	.phaeno-mainNav__items li:first-child {
		display: inline;
	}

	.phaeno-hamburger {
		visisbility: hidden !important;
	}

	.phaeno-subpageNav {
		background-color: #fff;
		padding-top: 1em;
	}

	.phaeno-subpageNav__item::after {
		content: "";
	}

	.phaeno-containerFull--first {
		padding-top: 8rem;
	}

	.phaeno-subpageNav__line {
		background-color: #000 !important;;
	}

	.phaeno-containerFull:not(.phaeno-containerFull--first) .phaeno-textBlock {
		padding-left: 1rem;
		padding-right: 1rem;
	}

}

@media only screen and (max-width: 870px) {

	.phaenos-header,
	.phaeno-mainNav__items li {
		display: block !important;
	}

}
