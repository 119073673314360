@mixin clearfix() {
  &:after {
	clear: both;
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
  }
}
@mixin clearfixdelete() {
  &:after {
	clear: none;
	content: none;
  }
}
