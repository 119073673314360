@for $i from 1 through 10 {
	.phaeno-animDelay#{$i} {
		transition-delay: $animSequenceDelay * $i;
	}

	.phaeno-animDelayed {
		.phaeno-animDelay#{$i} {
			transition-delay: $animDelay + $animSequenceDelay * $i;
		}
	}
}

@for $i from 1 through 10 {
	.phaeno-showable.show {
		.phaeno-animDelayShow#{$i} {
			transition-delay: $animSequenceDelay * $i;
		}
	}

	.phaeno-animDelayed {
		.phaeno-animDelayShow#{$i} {
			transition-delay: $animDelay + $animSequenceDelay * $i;
		}
	}
}
