@import "../global/variables";

.phaeno-slider {
	position: relative;
	margin-bottom: $baseSize4;

	&__slides {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: $sliderImageProportion;
	}
	&--fullHeight {
		.phaeno-slider__slides {
			height: 100vh;
			max-height: $maxHeaderHeight;
			padding-bottom: 0;
		}
	}

	&--main {
		margin-top: $baseSize + $baseSize2;
		.phaeno-slider__slides {
			min-height: $minHeaderHeight;
			//padding-bottom: 0;
		}
	}

	&__slide {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;

		opacity: 0;
		@include ease(opacity, $animDuration4);

		&:not(.active) {
			pointer-events: none;

			* {
				pointer-events: none !important;
			}
		}

		&.active {
			opacity: 1;
			@include ease(opacity, $animDuration4);
			z-index: 1;

			.phaeno-slider__head--shifting {
				padding-left: 0;
			}
		}
	}

	&__texts {
		position: relative;
		z-index: 5;
	}

	&__text {
		opacity: 0;
		position: relative;

		@include ease(opacity, $animDuration8);

		.phaeno-slider__head--shifting {
			padding-left: $baseSize;
			@include ease(padding-left, $animDuration2);
		}

		&--video {
			background-color: black;
			padding-top: 5%;
		}

		&:not(.active) {
			pointer-events: none;

			* {
				pointer-events: none !important;
			}
		}

		&.active {
			opacity: 1;
			.phaeno-slider__head--shifting {
				padding-left: 0;
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		position: relative;
	}
	&__caption {
		background-color: $colorBgAlpha;
		color: $colorTextSecond;
		position: absolute;
		width: 100%;
		bottom: 0;
		padding: $baseSize4 $baseSize2 $baseSize4 $baseSize4;
	}
	&__head {
		@include sansSerifBlack;
		font-size: 4.5rem;
		line-height: 4.5rem;
		max-width: 90%;
		position: absolute;
		bottom: $baseSize4;
		text-transform: uppercase;
		left: $baseSize2;
		max-height: 135px;
		overflow: scroll;
		padding-top:10px;

		@include breakpoint($breakSmall) {
			font-size: 5.5rem;
			line-height: 5.5rem;
			max-height: unset;
			overflow: visible;
		}
		@include breakpoint($breakMedium) {
			font-size: 9rem;
			line-height: 9rem;
			overflow: visible;
		}
	}

	&__copy {
		@include sansSerifBlack;
		@include fontSizeHeadSmall;

		color: $colorText;

		max-width: 70%;
		position: absolute;
		bottom: $baseSize2;
		text-transform: uppercase;

		opacity: 0;
		@include ease(opacity);
	}

	&__slide:hover {
		.phaeno-slider__copy {
			opacity: 1;
		}
	}

	&__video {
		display: block;
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 0;
		height: 0;
		overflow: hidden;
		cursor: pointer;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border:none;
		}
	}

	&__nextPrev {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		z-index: 3;
		opacity: 1;
		@include breakpoint($breakMedium) {
			opacity: 0;
			@include ease(opacity);
		}
	}
	&:hover {
		.phaeno-slider__nextPrev {
			@include breakpoint($breakMedium) {
				opacity: 1;
			}
		}
	}

	.phaeno-dashMenu {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 8;

		@include breakpoint($breakSmall) {
			right: -$baseSize;
		}

		&--overlay {
			right: 0;
			width: 1.5rem;
			background-color: white;
			padding: 1rem 0 0 0;
			border-top-left-radius: 5px;
		}
	}
}
