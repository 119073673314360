.phaeno-calendarButton {
	position: relative;

	display: inline-block;
	padding: $baseSize16 $baseSize4;
	margin: 0 $baseSize8;

	color: $colorTextHighlighted !important;
	background-color: $colorBgHighlighted;

	border-radius: $calendarEventHeight;
	font-weight: bold;

	@include fontSizeSubtext;

	&:hover,
	&:focus {
		border: 0 !important;
	}
}
