@import "../global/variables";

.phaeno-textBlock {
	position: relative;
	width: 100%;

	@include breakpoint($breakSmall) {
		//width: 75%; //restrict line length
		max-width: $maxTextWidth;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	ul,
	ol {
		margin-bottom: $baseSize4;
		clear: both;
	}

	h4 {
		margin-top: $baseSize8;
		margin-bottom: $baseSize8;
		color: $colorTextSecond;
	}

	a {
		@include sansSerifMedium;
		cursor: pointer;
		color: $colorText;
		border: 0;
		text-decoration: none;

		&:hover,
		&:focus {
			border: 0;
			border-bottom: 1px solid $colorText;
		}
	}
	p a {
		white-space: nowrap;
	}

	ul,
	ol {
		color: $colorTextSecond;
		padding-left: 3 * $baseSize4;
		list-style-position: outside;

		ul,
		ol {
			margin-left: 2 * $baseSize4;
		}

		ul {
			list-style-type: circle;
		}

		ol {
			list-style: lower-latin;
		}
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style: decimal;
	}

	dl dt {
		color: $colorText;
	}

	&__head {
		text-transform: uppercase;
	}

	&__byline {
		display: block;
		text-transform: none;
		font-weight: normal;

		@include fontSizeCopy;
		@include sansSerifMedium;

		@include breakpoint($breakMenu) {
			display: inline-block;
		}

		&--newline {
			clear:both;
			padding-left: 0;
		}

	}

	&__subtext {
		@include fontSizeCopySmall;
	}

	&--fulllength {
		width: 100%;
	}

	&--intext {
		p {
			clear: none;
		}
	}

	blockquote {
		background: $colorByNameGreyLightest;
		border-left: 10px solid $colorByNameRed;
		padding: 1.5em 1em;
		margin: 1.5em 0 !important;
		line-height: 1.6em;
		display: grid;
	}

	blockquote p {
		display: inline;
		font-size: 1.05em
	}

	/** justify **/
	.text-left {
		text-align: left;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

	.text-justify {
		text-align: justify;
	}

}
