@import "../global/variables";

.phaeno-table {
	margin-left: $baseSize4;
	margin-right: $baseSize4;
	width:auto;

	@include breakpoint($breakSmall) {
		margin-left: 0;
		margin-right: 0;
		width:100%;
	}

	&__cell {
		padding: $baseSize8 0;

		&--horizontalSeperated {
			border-bottom: 1px dotted $colorByNameGreyLighter;
		}
		&--rightAligned {
			text-align: right;
		}
		&--topAligned {
			vertical-align: top;
		}
		&--leftPadding {
			padding-left: $baseSize2;
		}
	}

}
