.phaeno-calendarEvent {
	display: none;
	position: relative;
	width: 100%;
	/*
	width: calc(100% + #{$calendarLeftColumnWidth} + #{$baseSize});
	margin-left: -$calendarLeftColumnWidth - $baseSize;
	*/
	z-index: 4;

	.phaeno-calendarEvent__content {
		@include clearfix;

		padding-top: $baseSize2;
		padding-bottom: $baseSize;

		@include breakpoint($breakMedium) {
			padding-left: $calendarLeftColumnWidth + $baseSize;
		}

		//background-color: $colorBg;
	}

	.phaeno-calendar__day--active &,
	.phaeno-calendar--filtered & {
		display: block;
	}

	&__head {
		font-weight: bold;
		margin-bottom: $baseSize4;
	}

	&__close {
		position: absolute;
		right: $baseSize8;
		top: $baseSize8;
		z-index: 2;
	}

	&__table {
		margin-top: $baseSize2;
		color: $colorTextSecond;
	}

	&__textName {
		width: $baseSizeLarge;
		font-weight: bold;
		color: $colorByNameRed;
	}
}
