.phaeno-infoTabMap {
	vertical-align: bottom;

	&__header {
		@include clearfix;
	}

	&__head {
		margin-top: 0;
		margin-bottom: 0;
	}

	&__menu {
		position: relative;
		margin-top: $baseSize4;
		margin-bottom: -$baseSize2;
	}

	&__menuNav {
		margin-left: 0;
		@include breakpoint($breakSmall) {
			margin-left: $baseSize4;
		}
	}

}
