.phaeno-imageLinks {
	margin-bottom: $baseSize;

	&__content {
		opacity: 0;

		min-height: $minSectionHeight;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		position: relative;
	}

	&__head {
		padding-top: $baseSize4;
	}

	&__nav {
		background-color: $colorBgAlpha;
		color: $colorTextSecond;
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		bottom: 0;
		padding: $baseSize4;
		padding-bottom: 0;
	}

	&__items {
		@include breakpoint($breakSmall) {
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
		}
	}


	&__item {
		margin-top: 0;
		padding: $baseSize2;

		display: block;
		float: none;
		@include breakpoint($breakSmall) {
			float: left;
			display: inline-block;
		}

		padding-top: 0;
		padding-bottom: 0;
		@include breakpoint($breakSmall) {
			padding-top: $baseSize4;
		}
	}
	&__itemLink {
		border: none;
	}

	&__itemHead {
		@include fontSizeCopy;
		display: inline-block;
		vertical-align: middle;
	}

	&__itemCopy {
		display: none;
		@include breakpoint($breakSmall) {
			display: block;
		}
	}

	&__buttonIcon {
		display: none;
		vertical-align: middle;
		transform: scale(-1, 1);
		width: $arrowIconSize;
		height: $arrowIconSize;
		svg {
			width: $arrowIconSize;
			height: $arrowIconSize;
		}
	}

	&__item:hover {
		color: $colorByNameRed;
		.phaeno-imageLinks__buttonIcon {
			display: none;
		}
	}
}
