@import "../global/variables";

.phaeno-containerFull {
	position: relative;

	padding-left: 0;
	padding-right: 0;
	pointer-events: none;

	* {
		pointer-events: auto;
	}

	@include breakpoint($breakSmall) {
		padding-left: $baseSize;
		padding-right: $baseSize;
	}

	&__text {
		padding-left: $baseSize4;
		padding-right: $baseSize4;

		@include breakpoint($breakSmall) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__content {
		position: relative;
		padding-left: 0;

		@include breakpoint($breakMedium) {
			padding-left: $baseSizeLarge;
		}
	}

	&__shifted {
		margin-left: $baseSize8;

		@include breakpoint($breakSmall) {
			margin-left: 0;
		}

		@include breakpoint($breakMedium) {
			margin-left: -$baseSizeLarge;
			max-width:calc(690px + 11rem);
		}
	}

	&__shifted--inbox {
		margin-left: $baseSize4;
		@include breakpoint($breakMedium) {
			margin-left: -$baseSizeLarge;
		}
	}

	&--first {
		padding-top: $baseSize * 2;
	}
}
