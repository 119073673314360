html, body {
	margin: 0;
	padding: 0;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
	color: inherit;
}

h1, h2, h3, h4, h5 {
	margin: 0;
}

button {
	border: none;
	font: inherit;
	padding: 0;
	cursor: pointer;
	background: none;
	@extend %no-outline;
}

p {
	margin: 0;
}

label {
	font-size: inherit;
}

input {
	border-radius: 0;
}

input:not([type=checkbox]),
label,
textarea {
	font-family: inherit;
	outline: 0;

	&:invalid {
		outline: 0;
		box-shadow: none;
	}
}

figure {
	margin: 0;
}

%no-outline {
	outline: 0;
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;

	th, td {
		margin: 0;
		padding: 0;
	}
}
