.phaeno-tabMenu {
	display: block;

	&__tabs {
		margin-top: $baseSize2;
		@include breakpoint($breakMedium) {
			margin-top: 0;
		}
		display: inline-block;
	}

	&__tab {
		display: inline-block;
	}

	&__button {
		min-width: $infoBoxTabSize;
		padding-top: $baseSize4;
		padding-bottom: $baseSize4;
		padding-left: $baseSize8;
		padding-right: $baseSize8;
		margin: 1rem;
		transition: background 0.3s ease-out;

		@include breakpoint($breakSmall) {
			margin: 0;
		}

		@include breakpoint($breakMaxSmall) {
			min-width: $infoBoxTabSizeMobile;
		}

		&:hover {
			background-color: $colorBgHover;
			border-radius: $backgroundCornerRadius;
			@include breakpoint($breakSmall) {
				border-radius: $backgroundCornerRadius $backgroundCornerRadius 0 0;
			}
		}
	}

	&__button.active {
		background-color: $colorBgChoosen;
		border-radius: $backgroundCornerRadius;
		@include breakpoint($breakSmall) {
			border-radius: $backgroundCornerRadius $backgroundCornerRadius 0 0;
		}
	}

	&__buttonIcon {
		width: $infoBoxIconSize;
		height: $infoBoxIconSize;
		svg {
			width: $infoBoxIconSize;
			height: $infoBoxIconSize;
		}
	}

	&__buttonText {
		color: $colorText;
		@include sansSerifMedium;
		display: block;
		margin-top: $baseSize8;
	}

	&--small {
		.phaeno-tabMenu__button {
			width: $infoBoxTabSizeSmall;
			min-width: unset;
			padding-top: $baseSize8;
			padding-bottom: $baseSize8;
		}
		.phaeno-tabMenu__button.active {
			background-color: $colorBg;
		}
		.phaeno-tabMenu__buttonIcon {
			width: $infoBoxIconSizeSmall;
			height: $infoBoxIconSizeSmall;
			svg {
				width: $infoBoxIconSizeSmall;
				height: $infoBoxIconSizeSmall;
			}
		}
	}

}
