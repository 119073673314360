.phaeno-exclusionContent {
	&__item {
		display: none;
	}
	&__item.active {
		display: block;
	}

	&--bg {
		.phaeno-exclusionContent__bg {
			background-color: $colorBg;
			padding: $baseSize2;
		}
	}
}
