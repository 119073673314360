.phaeno-style-2 {

	.phaeno-dashMenu__button.active {
		background-color: #00A1C2;;
	}

	#arrow_back > path {
		stroke: #00A1C2;
	}

	.phaeno-subpageNav__nav {
		margin-bottom: 1.6rem !important;
	}

	.phaeno-bookmark,
	.phaenos-header,
	.phaeno-mainNav__items li {
		display: none;
	}

	.phaeno-header {
		border-bottom: 0 !important;
	}

	.phaeno-mainNav__items li:first-child {
		display: inline;
		color: #E40038;
	}

	.phaeno-header {
		position: relative;

	}

	.phaeno-hamburger {
		visibility: hidden !important;
	}

	.phaeno-subpageNav {
		position: sticky;
		top: 0;
		z-index: 3;
		background-color: #fff;
		padding-top: 1em;
	}

	.phaeno-subpageNav__item::after {
		content: "";
	}

	.phaeno-mainNav__items li:first-child a {
		color: #E40038 !important;
	}

	.phaeno-footer a,
	.phaeno-footer__bottom,
	.phaeno-footer__bottom a {
		color: #fff !important;
	}

	header, a, body {
		color: #00A1C2 !important
	}

	.phaeno-subpageNav__line {
		background-color: #00A1C2 !important;
	}

	blockquote {
		border-left: 10px solid #00A1C2 !important;
		background: #f9f9f9;
		padding: 1.5em 1em;
		margin: 1.5em 0 !important;
		line-height: 1.6em;
		display: grid;
		font-family: Georgia, serif;

		p {
			display: inline;
			font-size: 1.05em
		}
	}
}

.phaeno-image--right {
	margin-left: 2rem;
}

.phaeno-image--intext {
	max-width: 50% !important;
}

@media only screen and (max-width: 600px) {

	.phaeno-style-2 {

		.phaeno-subpageNav__items {
			display: none;
		}

	}

	.phaeno-image--intext {
		max-width: 100% !important;
	}

	.phaeno-containerFull__shifted {
		margin-left: 0;
	}

	.phaeno-image--left {
		margin: 0.5em 0 !important;
	}

	.phaeno-image--right {
		margin: 0.5em 0 !important;
	}

}
