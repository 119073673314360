.phaeno-swapOrder {
	display: flex;
	flex-direction: column;
	@include breakpoint($breakMedium) {
		flex-direction: row;
	}

	&__one {
		order: 1;
		@include breakpoint($breakMedium) {
			order: 2;
		}
	}

	&__two {
		order: 2;
		@include breakpoint($breakMedium) {
			order: 1;
		}
	}
}
