.phaeno-nextPrevTabMenu {

	//next prev tab menu switches from 'next prev' behaviour on mobiles
	//to 'tab' behaviour on bigger screens.

	&__nextPrev {
		display: block;
		@include breakpoint($breakMedium) {
			display: none;
		}
	}
	&__tab {
		display: none;
		@include breakpoint($breakMedium) {
			display: block;
		}
	}
}
